<template>
  <div class="wrapper">
    <div class="m-4 p-4">
    <div class="mt-3">
      <b-row>
        <b-col>
          <ul v-for="(item, index) in QualificationList" :key="index">
            <li
              style="display: flex; padding:10px;justify-content: space-between; align-items: center;"
              @click="RefreshItem(item)"
              :class="{
                Li_First: TabIndex == item.id,
                Li_Hover_class: true,
              }"
            >
              <a class="linkBlue" v-bind:class="{ link: TabIndex == item.id }"
                ><span>{{ item.name }}</span></a
              >
              <b-spinner v-if="Loading" small></b-spinner>
              <b-icon icon="chevron-right"></b-icon>
            </li>
          </ul>
        </b-col>
        <div
          :class="{ ChangePlace: changeplace }"
          :style="{
            borderLeft: '1px solid #000',
            minHeight: '600px',
          }"
        ></div>
        <b-col style="background-color:white" class="text-left">
          <!-- <ul>
            <li>
              <p v-if="HeaderText == ''" style="font-size:20px">
                <b>{{ Regulations.headertext }}</b>
              </p>
              <p style="font-size:20px">
                <b>{{ HeaderText }}</b>
              </p>
            </li>
          </ul> -->
          <ul v-for="(item, index) in QualificationRequirmentItem" :key="index">
            <li
              @click="RefreshItemGet(item)"
              :class="{
                Li_First_Second: TabIndexSecond == item.id,
                Li_Hover_class: true,
              }"
              style="display: flex; padding:10px;justify-content: space-between; align-items: center;"
            >
              <a
                class="linkBlue"
                v-bind:class="{ link: TabIndexSecond == item.id }"
                ><span>{{ item.name }}</span></a
              >
              <b-spinner v-if="Loading" small></b-spinner>
              <b-icon icon="chevron-right"></b-icon>
            </li>
          </ul>
        </b-col>
        <div
          :class="{ ChangePlace: LineChangePlace }"
          v-if="this.changeplace"
          :style="{
            borderLeft: '1px solid #000',
            minHeight: '600px',
          }"
        ></div>
        <b-col style="background-color:white;">
          <ul v-for="(item, index) in QualificationRequirmentItemGet.Tables" :key="index">
           <p v-html="item.name"></p>
          </ul>
        </b-col>
        <div
          :class="{ ChangePlace: ChangeLocation }"
          v-if="this.LineChangePlace"
          :style="{
            borderLeft: '1px solid #000',
            minHeight: '600px',
          }"
        ></div>
        <!-- <b-col sm="12" lg="5">
          <h3 class="text-center mb-3 pb-3">{{ Regulation.headertext }}</h3>
          <div v-html="Regulation.maintext"></div>
        </b-col> -->
      </b-row>
    </div>
  </div>
  </div>
</template>
<script>
import QualificationService from "@/services/Qualification.service";
import QualificationRequirmentService from "@/services/QualificationRequirment.service"
export default {
  data() {
    return {
        TabIndex:0,
        TabIndexSecond:0,
        TabIndexThird:0,
        changeplace: false,
      LineChangePlace: false,
      ChangeLocation: false,
      isLoading: false,
       Loading: false,
      filter: {
        Search: "",
        SortColumn: "",
        OrderType: "asc",
        Offset: "",
        PageNumber: 1,
        PageLimit: 20,
        lang: "",
        perPageOptions: [10, 20, 50, 100],
        totalRows: 0,
      },
      filter2: {
        qualificationid: 0,
        Search: "",
        SortColumn: "",
        OrderType: "asc",
        Offset: "",
        PageNumber: 1,
        PageLimit: 20,
        lang: "",
        perPageOptions: [10, 20, 50, 100],
        totalRows: 0,
      },
      QualificationList: [],
      QualificationRequirmentItem:[],
      QualificationRequirmentItemGet:{}
    };
  },
  created() {
    this.filter.lang = localStorage.getItem("lang") || "uz_cyrl";
    this.Refresh();
   
  },
  methods: {
    Refresh(el) {
      this.isLoading = true;
        this.LineChangePlace = false;
      this.ChangeLocation = false;
      QualificationService.GetList(
        this.filter.Search,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.Offset,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.lang
      )
        .then((res) => {
          this.QualificationList = res.data.rows;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notification(error.response.data.error, "danger");
        });
    },
    RefreshItem(item) {
        this.changeplace= false
           this.TabIndex = item.id
        this.QualificationRequirmentItemGet.Tables = []
      QualificationRequirmentService.GetList(
        item.id,
        this.filter.Search,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.Offset,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.lang
      )
        .then((res) => {
          this.QualificationRequirmentItem = res.data.rows;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notification(error.response.data.error, "danger");
        });
    },
    RefreshItemGet(item){
         this.changeplace= true
         this.TabIndexSecond = item.id
        QualificationRequirmentService.Get(
        item.id,
        this.filter.lang
      )
        .then((res) => {
          this.QualificationRequirmentItemGet = res.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notification(error.response.data.error, "danger");
        });
    }
  },
};
</script>
<style scoped>
.iconLocation {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.Li_Hover_class:hover {
  background-color: #c7cbce93;
}
.Li_First_Second {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  transition: opacity 0.5s;
  font-weight: bold;
  background-color: #70b0ff;
  z-index: 2;
  margin-bottom: 0px !important;
}
.Li_First_Third {
  cursor: pointer;
  display: flex;
  transition: opacity 0.5s;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  background-color: #70b0ff;
  z-index: 3;
  margin-bottom: 0px !important;
}
.Li_First_Second:hover {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: underline;
  padding: 5px;
  transition: opacity 0.5s;
  font-weight: bold;
  background-color: #70b0ff;
  z-index: 2;
  margin-bottom: 0px !important;
}
.Li_First_Third:hover {
  cursor: pointer;
  display: flex;
  transition: opacity 0.5s;
  justify-content: space-between;
  text-decoration: underline;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  background-color: #70b0ff;
  z-index: 3;
  margin-bottom: 0px !important;
}
.ChangePlaceBack {
  margin-left: 0%;
}
.ChangePlace {
  transition-duration: 800ms;
  margin-left: -20%;
  z-index: 2;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.Li_First {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s;
  padding: 5px;
  font-weight: bold;
  background-color: #70b0ff;
  margin-bottom: 0px !important;
}
.Li_First:active {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #70b0ff;
  margin-bottom: 0px !important;
}
.Li_First:hover {
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #70b0ff;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 0px !important;
}
.Black {
  color: black;
  font-size: 22px;
  font-weight: bold;
}
.linkSecond span {
  color: #1d70b8;
  text-decoration: underline;
  font-size: 16px;
}

.linkSecond:hover span {
  font-weight: bold;
}

.linkSecond:hover {
  text-decoration: underline;
}
.link span:active {
  color: white;
  text-decoration: underline;
  font-size: 18px;
}

.linkBlue span {
  color: #1d70b8;
  text-decoration: underline;
  font-size: 16px;
}
.linkBlue span:active {
  color: #1d70b8;
  text-decoration: underline;
  font-size: 16px;
}
/* .linkBlue span:hover {
  color: #1660a2;
  text-decoration: underline;
  font-size: 18px;
} */
.link span {
  color: white;
  text-decoration: underline;
  font-size: 16px;
}

/* .link span:hover {
  color: white;
  font-weight: bold;
} */

.link:hover {
  text-decoration: underline;
}
li {
  display: block;
  /* transition-duration: 0.5s; */
}

li:active {
  cursor: pointer;
  display: block;
}
li:hover {
  cursor: pointer;
}
ul {
  margin-bottom: 1px !important;
}
ul li ul {
  margin-bottom: 0px !important;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  /* transition: all 0.5s ease; */
  margin-top: 1rem;
  left: 0;
  display: none;
}

ul li:active > ul,
ul li ul:active {
  visibility: visible;
  opacity: 1;
  display: block;
  margin-bottom: 0px !important;
}

ul li ul li {
  margin-bottom: 0px !important;
  clear: both;
  width: 100%;
}
</style>
