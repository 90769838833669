var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"m-4 p-4"},[_c('div',{staticClass:"mt-3"},[_c('b-row',[_c('b-col',_vm._l((_vm.QualificationList),function(item,index){return _c('ul',{key:index},[_c('li',{class:{
              Li_First: _vm.TabIndex == item.id,
              Li_Hover_class: true,
            },staticStyle:{"display":"flex","padding":"10px","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.RefreshItem(item)}}},[_c('a',{staticClass:"linkBlue",class:{ link: _vm.TabIndex == item.id }},[_c('span',[_vm._v(_vm._s(item.name))])]),(_vm.Loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('b-icon',{attrs:{"icon":"chevron-right"}})],1)])}),0),_c('div',{class:{ ChangePlace: _vm.changeplace },style:({
          borderLeft: '1px solid #000',
          minHeight: '600px',
        })}),_c('b-col',{staticClass:"text-left",staticStyle:{"background-color":"white"}},_vm._l((_vm.QualificationRequirmentItem),function(item,index){return _c('ul',{key:index},[_c('li',{class:{
              Li_First_Second: _vm.TabIndexSecond == item.id,
              Li_Hover_class: true,
            },staticStyle:{"display":"flex","padding":"10px","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.RefreshItemGet(item)}}},[_c('a',{staticClass:"linkBlue",class:{ link: _vm.TabIndexSecond == item.id }},[_c('span',[_vm._v(_vm._s(item.name))])]),(_vm.Loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('b-icon',{attrs:{"icon":"chevron-right"}})],1)])}),0),(this.changeplace)?_c('div',{class:{ ChangePlace: _vm.LineChangePlace },style:({
          borderLeft: '1px solid #000',
          minHeight: '600px',
        })}):_vm._e(),_c('b-col',{staticStyle:{"background-color":"white"}},_vm._l((_vm.QualificationRequirmentItemGet.Tables),function(item,index){return _c('ul',{key:index},[_c('p',{domProps:{"innerHTML":_vm._s(item.name)}})])}),0),(this.LineChangePlace)?_c('div',{class:{ ChangePlace: _vm.ChangeLocation },style:({
          borderLeft: '1px solid #000',
          minHeight: '600px',
        })}):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }