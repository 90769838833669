import ApiService from "./api.service";
const QualificationRequirmentService = {
  GetList(
    qualificationid,
    Search,
    SortColumn,
    OrderType,
    Offset,
    PageNumber,
    PageLimit,
    lang
  ) {
    return ApiService.get(
      `/QualificationRequirment/GetList?qualificationid=${qualificationid}&Search=${Search}&SortColumn=${SortColumn}&OrderType=${OrderType}&Offset=${Offset}&PageNumber=${PageNumber}&PageLimit=${PageLimit}&lang=${lang}`
    );
  },
  Update(data) {
    return ApiService.post(`/QualificationRequirment/Update`, data);
  },
  UpdateTable(data) {
    return ApiService.post(`/QualificationRequirment/UpdateTable`, data);
  },
  Get(id, lang) {
    return ApiService.get(`/QualificationRequirment/Get?id=${id}&lang=${lang}`);
  },
  Delete(id) {
    return ApiService.delete(`/QualificationRequirment/Delete?id=${id}`);
  },
};
export default QualificationRequirmentService;
