import ApiService from "./api.service";
const QualificationService = {
  GetList(Search, SortColumn, OrderType, Offset, PageNumber, PageLimit, lang) {
    return ApiService.get(
      `/Qualification/GetList?Search=${Search}&SortColumn=${SortColumn}&OrderType=${OrderType}&Offset=${Offset}&PageNumber=${PageNumber}&PageLimit=${PageLimit}&lang=${lang}`
    );
  },
  Update(data) {
    return ApiService.post(`/Qualification/Update`, data);
  },
  Get(id, lang) {
    return ApiService.get(`/Qualification/Get?id=${id}&lang=${lang}`);
  },
  Delete(id) {
    return ApiService.delete(`/Qualification/Delete?id=${id}`);
  },
};
export default QualificationService;
